import {useEffect, useRef, useState} from 'react'

const useIntersectionObserverWithTop = (top?: number) => {
    const ref = useRef<HTMLDivElement>(null)
    const [observer, setObserver] = useState(false)
    useEffect(() => {
        if (!ref.current) return

        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.boundingClientRect.top <= (top || 0) && !e.isIntersecting) {
                    setObserver(true)
                    return
                }
                if (e.boundingClientRect.top > (top || 0) || e.isIntersecting) {
                    setObserver(false)
                }
            },
            {threshold: [1]},
        )
        observer.observe(ref.current)
        return () => observer.disconnect()
    }, [ref])
    return {observer, ref}
}
export default useIntersectionObserverWithTop
