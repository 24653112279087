import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import GetFreeTokenImageEn from '@image/token/image_token_landing_get_token_service_en.png'
import GetFreeTokenImageKo from '@image/token/image_token_landing_get_token_service_ko.png'
import {useIsVisible} from '@hook/useIsVisible'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import classNames from 'classnames'
import Link from 'next/link'
import WizImage from '@component/images/WizImage'
import {useRouter} from 'next/router'
import TagText from '@component/Text/TagText'
import WizButton from '@component/buttons/WizButton'
import {getLinkUrl, URL_ACCOUNT_POINT} from '@constant/url'
import {useLoginModalStore} from '@store/LoginModalStore'
import useLogin from '@hook/useLogin'

interface IProps {
    className?: string
}

const TokenServiceLOTTokenContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const language = useRouter()?.locale

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const {isLogin} = useLogin()

    return (
        <div
            className={`relative bg-[#1F1F26] px-[20px] py-[200px] land:py-[150px] md:py-[100px] sm:py-[70px] land:px-[40px] md:px-[20px] ${className}`}>
            <div className={`layout-full_desktop max-w-[1280px]`}>
                <div className={'flex items-center gap-x-[100px] land:gap-x-[80px]'}>
                    <div ref={fadeAnimationRef} className={'w-full flex flex-col md:items-center'}>
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                            <Text
                                className={
                                    'mb-[40px] text-white text-landing_heading2 md:text-landing_body_large md:font-semibold md:text-center !font-landing'
                                }>
                                {t('landingTokenSale.TokenService.get.free.token.title')}
                            </Text>
                        </FadeInView>
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE} delay={'delay-300'}>
                            <Text
                                className={
                                    'mb-[15px] text-[#C9CAC3] text-landing_body_medium md:text-landing_body_caption md:text-center !font-landing'
                                }>
                                {t('landingTokenSale.TokenService.get.free.token.desc')}
                            </Text>
                        </FadeInView>
                        <Link
                            onClick={e => {
                                if (!isLogin()) {
                                    showLoginModal()
                                    e?.preventDefault()
                                    e?.stopPropagation()
                                }
                            }}
                            href={getLinkUrl(URL_ACCOUNT_POINT)}>
                            <WizButton
                                text={t('landingTokenSale.TokenService.go.point.text.button')}
                                className={
                                    'bg-gradient-token hover:bg-gradient-token-hover active:bg-gradient-token-active rounded-full'
                                }
                                textClassName={'text-[#030304] typo-landing_body_small md:typo-landing_body_caption'}
                            />
                        </Link>

                        <div className={'max-w-400px w-full hidden md:flex md:mt-[50px]'}>
                            <WizImage src={language === 'ko' ? GetFreeTokenImageKo : GetFreeTokenImageEn} />
                        </div>

                        <div className={'mt-[50px] sm:mt-[30px] space-y-[20px]'}>
                            <Text
                                className={
                                    'text-white text-landing_body_large font-semibold md:text-landing_body_small md:text-center md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenService.get.free.token.subtitle')}
                            </Text>
                            <div className={'flex items-center sm:justify-center gap-x-[20px] sm:gap-x-[5px]'}>
                                <div
                                    className={
                                        'w-[130px] h-[130px] sm:w-[100px] sm:h-[100px] p-[12px] sm:p-[9px] bg-gradient-to-r from-[#6AE6A4] to-[#1EB0F8]  rounded-full'
                                    }>
                                    <Text
                                        className={classNames(
                                            'w-full h-full rounded-full bg-[#1F1F26] flex justify-center items-center text-center',
                                            'text-white text-landing_body_caption font-semibold md:text-landing_body_caption2 md:font-semibold !font-landing',
                                        )}>
                                        {t('landingTokenSale.TokenService.get.free.token.mission1')}
                                    </Text>
                                </div>
                                <div
                                    className={
                                        'w-[130px] h-[130px] sm:w-[100px] sm:h-[100px] p-[12px] sm:p-[9px] bg-gradient-to-r from-[#71E4C9] to-[#E9DD6F] rounded-full'
                                    }>
                                    <Text
                                        className={classNames(
                                            'w-full h-full rounded-full bg-[#1F1F26] flex justify-center items-center text-center',
                                            'text-white text-landing_body_caption font-semibold md:text-landing_body_caption2 md:font-semibold !font-landing',
                                        )}>
                                        {t('landingTokenSale.TokenService.get.free.token.mission2')}
                                    </Text>
                                </div>
                                <div
                                    className={
                                        'w-[130px] h-[130px] sm:w-[100px] sm:h-[100px] p-[12px] sm:p-[9px] bg-gradient-to-r from-[#F5E08A] to-[#FFA31E]  rounded-full'
                                    }>
                                    <Text
                                        className={classNames(
                                            'w-full h-full rounded-full bg-[#1F1F26] flex justify-center items-center text-center',
                                            'text-white text-landing_body_caption font-semibold md:text-landing_body_caption2 md:font-semibold !font-landing',
                                        )}>
                                        {t('landingTokenSale.TokenService.get.free.token.mission3')}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'w-full md:hidden'}>
                        <WizImage src={language === 'ko' ? GetFreeTokenImageKo : GetFreeTokenImageEn} />
                    </div>
                </div>
                <div className={'mt-[30px]'}>
                    <Text className={'text-[#C9CAC3] typo-landing_body_caption text-center'}>
                        {t('landingTokenSale.TokenService.bottom.desc1')}
                    </Text>
                    <TagText className={'text-[#C9CAC3] typo-landing_body_caption text-center'}>
                        {t('landingTokenSale.TokenService.bottom.desc2')}
                    </TagText>
                </div>
            </div>
        </div>
    )
}
export default TokenServiceLOTTokenContainer
