import React from 'react'
import WebBgSource from '@image/token/bg_lot_token_sale_web.png'
import TabletBgSource from '@image/token/bg_lot_token_sale_tablet.png'
import MobileBgSource from '@image/token/bg_lot_token_sale_mobile.png'
import useWindowSize from '@hook/useWindowSize'
import TokenSaleTitleContainer from '@feature/landing/pages/token/containers/TokenSaleTitleContainer'
import TokenSaleBox from '@feature/landing/pages/token/containers/TokenSaleBox'
import useIntersectionObserverWithTop from '@hook/useIntersectionObserverWithTop'
import TokenLandingTabs from '@feature/landing/pages/token/components/TokenLandingTabs'
import classNames from 'classnames'
import WizImage from '@component/images/WizImage'

interface IProps {
    className?: string
}

const TokenSaleContainer: React.FC<IProps> = ({className}) => {
    const {isMd, isCustom} = useWindowSize(1325)
    const {observer: isVisible, ref: tabRef} = useIntersectionObserverWithTop(55)

    return (
        <>
            <section className={`relative w-full max-w-[1280px] mx-auto ${className}`}>
                <WizImage src={MobileBgSource} containerClassName={'absolute inset-0 hidden sm:flex'} />
                <WizImage src={TabletBgSource} containerClassName={'absolute inset-0 hidden md:flex sm:hidden'} />
                <WizImage
                    src={WebBgSource}
                    width={1920}
                    height={1024}
                    containerClassName={'absolute top-0 left-0 md:hidden'}
                />
                <div
                    className={
                        'relative pt-[150px] pb-[100px] land:pt-[100px] land:pb-[50px] md:pt-[80px] md:pb-[30px] md:py-[30px]'
                    }>
                    <div
                        className={
                            'layout-full_desktop max-w-[1280px] flex items-end gap-x-[80px] px-[20px] land:flex-col land:gap-y-[30px] land:px-[40px] sm:px-[20px]'
                        }>
                        <TokenSaleTitleContainer />
                        <TokenSaleBox />
                    </div>
                    <div ref={tabRef} className={'h-[50px] w-full invisible absolute bottom-0 left-0'} />
                </div>

                <TokenLandingTabs className={`${isVisible ? 'hidden' : 'flex'} land:hidden`} />
            </section>
            <TokenLandingTabs
                tabRef={tabRef}
                className={classNames(
                    'sticky top-[50px] border-b',
                    isVisible ? '!bg-gray01 border-white/20 z-50' : 'border-transparent bg-[#1F1F26]/50',
                    !isVisible && !isCustom && 'hidden',
                )}
            />
        </>
    )
}
export default TokenSaleContainer
