import React, {CSSProperties} from 'react'
import classNames from 'classnames'

interface IProps {
    className?: string
}

interface IProps {
    className?: string
    round?: number
}
const GradientStyle: CSSProperties = {
    WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'destination-out',
    maskComposite: 'exclude',
}

const BorderGradientView: React.FC<IProps> = ({className, round}) => {
    return (
        <div
            className={classNames(
                'absolute inset-0 border border-solid border-transparent bg-origin-border bg-gradient-token',
                round ? `rounded-[${round}px]` : 'rounded-full',
                className,
            )}
            style={GradientStyle}
        />
    )
}
export default BorderGradientView
