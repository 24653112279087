import React, {useRef} from 'react'
import StakingInfoImageEn from '@svg/token/image_token_landing_staking_info_en.svg'
import StakingInfoImageTabletEn from '@svg/token/image_token_landing_staking_info_tablet_en.svg'
import StakingInfoImageMobileEn from '@svg/token/image_token_landing_staking_info_mobile_en.svg'
import StakingInfoImageKo from '@svg/token/image_token_landing_staking_info_ko.svg'
import StakingInfoImageTabletKo from '@svg/token/image_token_landing_staking_info_tablet_ko.svg'
import StakingInfoImageMobileKo from '@svg/token/image_token_landing_staking_info_mobile_ko.svg'
import Text from '@component/Text/Text'
import {useIsVisible} from '@hook/useIsVisible'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
interface IProps {
    className?: string
}

const TokenInfoStakingContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const language = useRouter()?.locale
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    return (
        <div
            className={`layout-full_desktop max-w-[1280px] px-[20px] py-[150px] md:py-[100px] sm:py-[50px] land:px-[40px] md:px-[20px] space-y-[70px] md:space-y-[50px] sm:space-y-[30px] ${className}`}>
            <div className={'flex flex-col items-center justify-center gap-y-[40px]'}>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                    <Text
                        className={
                            'text-white text-landing_heading2 text-center md:text-landing_body_large md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.staking.title')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-300'}>
                    <Text
                        className={
                            'text-[#C9CAC3] text-landing_body_medium text-center md:text-landing_body_caption !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.staking.desc')}
                    </Text>
                </FadeInView>
            </div>

            <div ref={fadeAnimationRef} className={'flex gap-x-[100px] md:gap-x-[30px] sm:flex-col sm:gap-y-[30px]'}>
                <div className={'w-full flex flex-col items-center gap-y-[20px] sm:items-start sm:gap-y-[10px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                        <Text
                            className={
                                'text-white text-landing_display2 text-center md:text-landing_body_small md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.stake.trade.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                        <Text
                            className={
                                'text-[#C9CAC3] text-landing_body_small md:text-landing_body_caption !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.stake.trade.desc')}
                        </Text>
                    </FadeInView>
                </div>
                <div className={'w-full flex flex-col items-center gap-y-[20px] sm:items-start sm:gap-y-[10px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                        <Text
                            className={
                                'text-white text-landing_display2 text-center md:text-landing_body_small md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.stake.earn.title')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                        <Text
                            className={
                                'text-[#C9CAC3] text-landing_body_small md:text-landing_body_caption !font-landing'
                            }>
                            {t('landingTokenSale.TokenInfo.stake.earn.desc')}
                        </Text>
                    </FadeInView>
                </div>
            </div>

            <div className={'flex flex-col items-center justify-center gap-y-[50px] md:gap-y-[30px]'}>
                {language === 'ko' ? (
                    <>
                        <StakingInfoImageKo className={'max-w-[1280px] md:hidden'} />
                        <StakingInfoImageTabletKo className={'hidden md:flex sm:hidden'} />
                        <StakingInfoImageMobileKo className={'hidden sm:flex'} />
                    </>
                ) : (
                    <>
                        <StakingInfoImageEn className={'max-w-[1280px] md:hidden'} />
                        <StakingInfoImageTabletEn className={'hidden md:flex sm:hidden'} />
                        <StakingInfoImageMobileEn className={'hidden sm:flex'} />
                    </>
                )}

                <Text
                    className={
                        'text-[#C9CAC3] text-landing_body_small text-center md:text-landing_body_caption !font-landing'
                    }>
                    {t('landingTokenSale.TokenInfo.staking.desc2')}
                </Text>
            </div>
        </div>
    )
}
export default TokenInfoStakingContainer
