import React, {useEffect, useState} from 'react'

type delayType = 'delay-100' | 'delay-300' | 'delay-500' | 'delay-700' | string

interface IProps {
    className?: string
    isVisible?: boolean
    enableOnce?: boolean
    delay?: delayType
    children?: React.ReactNode
}

const FadeInView: React.FC<IProps> = ({className, isVisible, enableOnce = true, delay = 'delay-300', children}) => {
    const [isShown, setIsShown] = useState(false)

    useEffect(() => {
        if (isVisible && enableOnce) {
            setIsShown(true)
        }
    }, [isVisible])

    return (
        <div
            className={`transition-all ease-in-out duration-1000 ${delay} delay ${
                isShown || isVisible ? 'opacity-1 translate-y-0' : 'opacity-0 translate-y-6'
            } ${className}`}>
            {children}
        </div>
    )
}
export default FadeInView
