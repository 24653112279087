import {useLoginStore} from '@store/LoginStore'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

const useLogin = (userID?: string) => {
    const id = useLoginStore(state => state.id)
    const router = useRouter()

    const [isMe, setIsMe] = useState(undefined)

    useEffect(() => {
        setIsMe(userID && id === userID)
    }, [userID, id])

    const isLogin = () => {
        return id && id.length > 0
    }

    const checkLoginRedirect = () => {
        if (!isLogin()) {
            router.push('/login')
            return true
        }

        return false
    }

    return {isLogin, isMe, checkLoginRedirect}
}

export default useLogin
